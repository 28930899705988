export const FriendsSvg = ({ color }) => {
    return (
        <svg width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_41_58)">
                <path d="M9.75 13C12.7725 13 15.2188 10.5537 15.2188 7.53125C15.2188 4.50879 12.7725 2.0625 9.75 2.0625C6.72754 2.0625 4.28125 4.50879 4.28125 7.53125C4.28125 10.5537 6.72754 13 9.75 13ZM13.5 14.5625H13.0947C12.0791 15.0508 10.9512 15.3438 9.75 15.3438C8.54883 15.3438 7.42578 15.0508 6.40527 14.5625H6C2.89453 14.5625 0.375 17.082 0.375 20.1875V21.5938C0.375 22.8877 1.4248 23.9375 2.71875 23.9375H16.7812C18.0752 23.9375 19.125 22.8877 19.125 21.5938V20.1875C19.125 17.082 16.6055 14.5625 13.5 14.5625ZM23.8125 13C26.4004 13 28.5 10.9004 28.5 8.3125C28.5 5.72461 26.4004 3.625 23.8125 3.625C21.2246 3.625 19.125 5.72461 19.125 8.3125C19.125 10.9004 21.2246 13 23.8125 13ZM26.1562 14.5625H25.9707C25.292 14.7969 24.5742 14.9531 23.8125 14.9531C23.0508 14.9531 22.333 14.7969 21.6543 14.5625H21.4688C20.4727 14.5625 19.5547 14.8506 18.749 15.3145C19.9404 16.5986 20.6875 18.3027 20.6875 20.1875V22.0625C20.6875 22.1699 20.6631 22.2725 20.6582 22.375H29.2812C30.5752 22.375 31.625 21.3252 31.625 20.0312C31.625 17.0088 29.1787 14.5625 26.1562 14.5625Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_41_58">
                    <rect width="31.25" height="25" fill="white" transform="translate(0.375 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}