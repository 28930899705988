export const ShopSvg = ({ color }) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.125 27.4887V23.125C18.125 21.9562 18.125 21.3725 17.8737 20.9375C17.7092 20.6525 17.4725 20.4158 17.1875 20.2513C16.7525 20 16.1687 20 15 20C13.8312 20 13.2475 20 12.8125 20.2513C12.5275 20.4158 12.2908 20.6525 12.1263 20.9375C11.875 21.3725 11.875 21.9562 11.875 23.125V27.4887H18.125Z" fill={color} />
            <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M7.165 15C6.0525 15 5.06625 14.53 4.375 13.7912V17.5C4.375 22.2137 4.375 24.5712 5.84 26.035C7.01875 27.215 8.775 27.4437 11.875 27.4887H18.125C21.225 27.4437 22.9812 27.2137 24.16 26.035C25.625 24.5712 25.625 22.2137 25.625 17.5V13.7925C25.1224 14.3275 24.4765 14.7067 23.7643 14.8846C23.0521 15.0626 22.3038 15.0318 21.6086 14.796C20.9134 14.5603 20.3007 14.1294 19.8437 13.555C19.3867 12.9805 19.1047 12.2866 19.0312 11.5562L18.94 10.6462C18.994 11.1942 18.9331 11.7473 18.7611 12.2704C18.5892 12.7935 18.3101 13.2749 17.9416 13.684C17.5731 14.0931 17.1233 14.4208 16.6209 14.6462C16.1186 14.8716 15.5748 14.9897 15.0242 14.993C14.4736 14.9963 13.9284 14.8848 13.4234 14.6654C12.9183 14.4461 12.4646 14.1239 12.0912 13.7192C11.7178 13.3146 11.4329 12.8366 11.2547 12.3156C11.0765 11.7947 11.0089 11.2423 11.0562 10.6937L10.97 11.5562C10.8755 12.4998 10.4338 13.3746 9.73075 14.0109C9.02767 14.6473 8.11328 14.9998 7.165 15ZM18.125 23.125V27.4887H11.875V23.125C11.875 21.9562 11.875 21.3725 12.1262 20.9375C12.2908 20.6525 12.5275 20.4158 12.8125 20.2512C13.2475 20 13.8312 20 15 20C16.1687 20 16.7525 20 17.1875 20.2512C17.4725 20.4158 17.7092 20.6525 17.8737 20.9375C18.125 21.3725 18.125 21.9562 18.125 23.125Z" fill={color} />
            <path d="M11.875 2.5H18.125L18.94 10.6462C18.9937 11.196 18.9318 11.7508 18.7582 12.2752C18.5846 12.7995 18.3032 13.2817 17.9322 13.6908C17.5611 14.0999 17.1085 14.4268 16.6035 14.6505C16.0985 14.8743 15.5523 14.9898 15 14.9898C14.4477 14.9898 13.9015 14.8743 13.3965 14.6505C12.8915 14.4268 12.4389 14.0999 12.0678 13.6908C11.6967 13.2817 11.4154 12.7995 11.2418 12.2752C11.0682 11.7508 11.0063 11.196 11.06 10.6462L11.875 2.5Z" fill={color} />
            <path opacity="0.7" d="M4.1625 6.68875C4.385 5.57625 4.49625 5.02 4.7225 4.56875C4.95838 4.09867 5.2905 3.68346 5.6973 3.35009C6.1041 3.01673 6.57648 2.77266 7.08375 2.63375C7.57125 2.5 8.1375 2.5 9.2725 2.5H11.8762L10.97 11.5562C10.9207 12.0735 10.7667 12.5752 10.5171 13.0309C10.2676 13.4866 9.9278 13.8867 9.51855 14.2067C9.10929 14.5268 8.63913 14.7601 8.13673 14.8925C7.63433 15.0248 7.11023 15.0535 6.59641 14.9766C6.08258 14.8997 5.5898 14.719 5.14812 14.4454C4.70643 14.1719 4.32511 13.8112 4.02742 13.3854C3.72972 12.9596 3.52189 12.4776 3.41659 11.9689C3.3113 11.4601 3.31076 10.9352 3.415 10.4262L4.1625 6.68875ZM25.8375 6.68875C25.615 5.57625 25.5037 5.02 25.2775 4.56875C25.0417 4.0988 24.7098 3.68367 24.3032 3.35031C23.8967 3.01696 23.4245 2.77282 22.9175 2.63375C22.43 2.5 21.8625 2.5 20.7275 2.5H18.125L19.0312 11.5562C19.0791 12.0745 19.2322 12.5776 19.4812 13.0347C19.7302 13.4917 20.0698 13.8932 20.4794 14.2144C20.8889 14.5356 21.3597 14.7699 21.8629 14.9029C22.3661 15.0358 22.8912 15.0647 23.406 14.9877C23.9207 14.9107 24.4144 14.7294 24.8566 14.455C25.2989 14.1806 25.6805 13.8188 25.9781 13.3918C26.2757 12.9648 26.4831 12.4815 26.5874 11.9716C26.6918 11.4617 26.6909 10.9358 26.585 10.4262L25.8375 6.68875Z" fill={color} />
        </svg>

    )
}

